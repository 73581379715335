export const checksums = {
  "blog": "L28UeqSClL",
  "page": "cvrPCMt3OX"
}

export const tables = {
  "blog": "_content_blog",
  "page": "_content_page",
  "info": "_content_info"
}

export default {
  "blog": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "extraTitle": "string",
      "category": "string",
      "image": "json",
      "cover": "string",
      "order": "number",
      "section_recommended": "boolean",
      "section_popular": "boolean",
      "section_media": "boolean",
      "section_blog": "boolean",
      "section_catalog": "boolean",
      "date": "string",
      "author": "string"
    }
  },
  "page": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json"
    }
  },
  "info": {
    "fields": {}
  }
}